/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withPrefix, Link, graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Components
import Section from '../../components/section';
import Form from '../../components/form';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import InputSwitch from '../../components/input-switch';
import Tips from '../../components/tips'; 
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';
import { checkNull } from '../../helpers/check-null';

const StampDuty = (props) => {
    const { getShareLink } = withShareResults();
    const PageContent = HTMLContent || Content;

    const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

    // React Redux hooks
	const dispatch = useDispatch();
    const query = useSelector(state => state.stampDuty);

    useEffect(() => {
        dispatch({ type: 'APPLY_STAMP_DUTY_URL_PARAMS' });

        return () => {
            dispatch({ type: 'RESET_STAMP_DUTY_SELECTIONS' });
        };
    }, []);

	const formatNumber = (value) => {
		let formatted = 0;
		
		if (value <= 0) {
			formatted = '0.00';
		} else {
			formatted = value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
		}
		
		return formatted;
    };

	const calcTotalStampDuty = () => {
        const propertyValue = checkNull(query.propertyValue);
        const nilRateBand = 250000;
        const firstTimeBuyerNilRateBand = 425000;
        const firstTimeBuyerCap = 625000;

		let result = 0;

		if (query.firstTimeBuyer && propertyValue <= firstTimeBuyerCap) {
			if (propertyValue > firstTimeBuyerNilRateBand) {
				result = (propertyValue - firstTimeBuyerNilRateBand) * 0.05;
			}
		}

		else if (!query.firstTimeBuyer || (query.firstTimeBuyer && propertyValue > firstTimeBuyerCap)) {
			let taxLevel1 = 0;
			let taxLevel2 = 0;
			let taxLevel3 = 0;
			let taxLevel4 = 0;
			let taxLevel5 = 0;

			if (propertyValue <= nilRateBand) {
				taxLevel1 = query.additionalProperty ? propertyValue * 0.03 : 0;
			}
			else if (propertyValue > nilRateBand && propertyValue <= 250000) {
				taxLevel1 = query.additionalProperty ? nilRateBand * 0.03 : 0;
				taxLevel2 = (propertyValue - nilRateBand) * (query.additionalProperty ? 0.02 + 0.03 : 0.02);
			}
			else if (propertyValue > 250000 && propertyValue <= 925000) {
				taxLevel1 = query.additionalProperty ? nilRateBand * 0.03 : 0;
				taxLevel2 = (250000 - nilRateBand) * (query.additionalProperty ? 0.02 + 0.03 : 0.02);
				taxLevel3 = (propertyValue - 250000) * (query.additionalProperty ? 0.05 + 0.03 : 0.05);
			}
			else if (propertyValue > 925000 && propertyValue <= 1500000) {
				taxLevel1 = query.additionalProperty ? nilRateBand * 0.03 : 0;
				taxLevel2 = (250000 - nilRateBand) * (query.additionalProperty ? 0.02 + 0.03 : 0.02);
				taxLevel3 = (925000 - 250000) * (query.additionalProperty ? 0.05 + 0.03 : 0.05);
				taxLevel4 = (propertyValue - 925000) * (query.additionalProperty ? 0.1 + 0.03 : 0.1);
			}
			else if (propertyValue > 1500000) {
				taxLevel1 = query.additionalProperty ? nilRateBand * 0.03 : 0;
				taxLevel2 = (250000 - nilRateBand) * (query.additionalProperty ? 0.02 + 0.03 : 0.02);
				taxLevel3 = (925000 - 250000) * (query.additionalProperty ? 0.05 + 0.03 : 0.05);
				taxLevel4 = (1500000 - 925000) * (query.additionalProperty ? 0.1 + 0.03 : 0.1);
				taxLevel5 = (propertyValue - 1500000) * (query.additionalProperty ? 0.12 + 0.03 : 0.12);
			}

			result = taxLevel1 + taxLevel2 + taxLevel3 + taxLevel4 + taxLevel5;
		}

		return result;
	};

	const calcEffectiveTaxRate = () => {
        const propertyValue = checkNull(query.propertyValue);
		let result = 0;

		if (calcTotalStampDuty() !== 0 && propertyValue !== 0) {
			result = (calcTotalStampDuty() / propertyValue) * 100;
		}

		return result;
    };

    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />

            <PageHeader
                heading={pageInfo.frontmatter.title}
                imageSource={`${withPrefix("/")}img/${headerImage}`}
                modalContent={(
                    <PageContent content={pageInfo.html} />
                )}
                description={pageInfo.frontmatter.description}
                breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Property', url: '/property'},
                ]}
            />
            
            <Section label='Calculator'>
                <Form>
                    <Form.Fieldset>
                        <Form.Row>
                            <InputField
                                id='property-value'
                                labelText='Property value'
                                placeholder='0'
                                symbol='currency'
                                min={0}
                                onChange={(event) => dispatch({
                                    type: 'SET_STAMP_DUTY_PROPERTY_VALUE',
                                    payload: event.target.value,
                                })}
                                required
                                value={query.propertyValue}
                            />
                        </Form.Row>

                        <Form.Row>
                            <InputSwitch
                                id="first-time-buyer"
                                onChange={() => dispatch({
                                    type: 'SET_STAMP_DUTY_FIRST_TIME_BUYER',
                                    payload: !query.firstTimeBuyer,
                                })}
                                checked={query.firstTimeBuyer}
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                            >
                                Are you a first-time buyer?
                            </InputSwitch>
                        </Form.Row>

                        <Form.Row>
                            <InputSwitch
                                id="additional-property"
                                onChange={() => dispatch({
                                    type: 'SET_STAMP_DUTY_ADDITIONAL_PROPERTY',
                                    payload: !query.additionalProperty,
                                })}
                                checked={query.additionalProperty}
                                modalHeading={definitions[0].frontmatter.title}
                                modalContent={<PageContent content={definitions[0].html} />}
                            >
                                Is new property an 'additional property'?
                            </InputSwitch>
                        </Form.Row>
                    </Form.Fieldset>
                </Form>
            </Section>

            <Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
                <ResultListing>
                    <ResultListing.Item 
                        heading='Stamp duty paid'
                        result={`£${formatNumber(calcTotalStampDuty())}`}
                    />

                    <ResultListing.Item 
                        heading='Effective tax rate'
                        result={`${formatNumber(calcEffectiveTaxRate())}%`}
                        modalHeading={definitions[1].frontmatter.title}
                        modalContent={<PageContent content={definitions[1].html} />}
                    />
                </ResultListing>

                <div className='summary-card__controls'>
                    <ShareResults url={getShareLink(query)} />
                </div>
            </Section>

            <Tips text={(
				<>
					<h2><span role='img' aria-hidden='true'>&#x1F3E0;</span> Buying your next property?</h2>
					<p>Get a breakdown of your future mortgage repayments with our <Link to="/property/adjustable-rate-mortgage">Mortgage Repayments Calculator</Link></p>
				</>
			)} />
        </Default>
    );
};

export default StampDuty;

export const StampDutyQuery = graphql`
    query StampDutyQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                image {
                    relativePath
                }
                description
            }
        }
        definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/first-time-buyer|src/definitions/additional-properties|src/definitions/effective-tax-rate/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;